
@font-face{
  font-family:"helvetica";
  src:url("https://candyfonts.com/wp-data/2018/10/26/11538/HELR45W.ttf") format("woff"),
  url("https://candyfonts.com/wp-data/2018/10/26/11538/HELR45W.ttf") format("opentype"),
  url("https://candyfonts.com/wp-data/2018/10/26/11538/HELR45W.ttf") format("truetype");
}


*{
  font-family: 'helvetica';
}


.overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgb(0, 0, 0), rgba(255, 255, 255, 0));
  }
 




  .wrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
    position: relative;
  }
  
  .card {
    width: 320px;
    height: 360px;
    padding: 1.5rem;
    background: white;
    position: relative;
    transition: 0.5s ease-out;
  }

  .card:hover:before {
    opacity: 1;
  }
  
  .card .info {
    opacity: 0;
    transition: 0.5s;
  }
  
  .card:hover .info {
    opacity: 1;
  }
 
  .card:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    z-index: 2;
    transition: 0.5s;
    opacity: 0;
  }
  
  .card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .card .info {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    position: relative;
    z-index: 3;
    color: white;
  }
  
  .card .info h1 {
    font-weight: normal;
  }
  
  .card .info p {
    font-size: 13px;
  }
  
  .card .info button {
    padding: 0.6rem;
    outline: none;
    border: none;
    border-radius: 3px;
    background: #C71B1B;
    color: white;
    cursor: pointer;
    transition: 0.5s ease;
    width: 200px;
    position: absolute;
    top: 300px;
  }
 













  


  .card-container {
    background-color: #141414;
    border-radius: 5px;
    color: white;
    position: relative;
    width: 250px;
    text-align: center;
    border: 2px solid #9F0202;
    min-height: 400px;
    border-radius: 20px;
    padding: 20px;
  }
  
  @media (max-width: 411px) {
    .card-container {
      background-color: #141414;
      border-radius: 5px;
      color: white;
      padding-top: 30px;
      position: relative;
      width: 250px;
      text-align: center;
      border: 2px solid #9F0202;
      padding: 20px;
      min-height: 400px;
      border-radius: 20px;
    }
    
  }
  


  .card-containerrr {
    background-color: #141414;
    border-radius: 5px;
    color: white;
    position: relative;
    width: 350px;
    text-align: center;
    border: 2px solid #9F0202;
    border-radius: 20px;
    padding: 20px;
    min-height: 450px;
  }

  @media (max-width: 500px) {
    .card-containerrr {
      background-color: #141414;
      border-radius: 5px;
      color: white;
      padding-top: 30px;
      position: relative;
      width: 250px;
      text-align: center;
      border: 2px solid #9F0202;
      padding: 20px;
      min-height: 550px;
      border-radius: 20px;
    }
    
  }


  .custom-swiper .swiper-pagination-bullet {
    background-color: white;
  }
  
  .custom-swiper .swiper-pagination-bullet-active {
    background-color: white;
  }
  
 


  .custom-textarea{
    background-color: transparent;
    border: 1px solid white;
    padding:10px 1px 1px 12px;
    border-radius: 5px;
    color: white;
    max-height: 400px;
    
  }

  .custom-textarea::placeholder {
    color: white;
    font-size:13px;
    font-family: 'helvetica';
    font-style: none;
  }
  p{
    font-family: 'helvetica';
   }

  .flag-dropdown{
    background-color: transparent !important;
  }
  .react-tel-input ::placeholder{
    color: white;
  }

